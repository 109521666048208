import './App.css'
import Portfolio from './components/Portfolio'

function App() {
  return (
    <div className='App'>
      <h1>ehh hahah</h1>
      <div>
        <p>Demo webpage.</p>
      </div>
      <Portfolio />
    </div>
  )
}

export default App
